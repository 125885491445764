import request from '@/utils/request'

const actionApi = {
    list: '/api/actions',
    addorupdateaction: '/api/action',
    getaction: '/api/actiondata/',
    deleteaction: '/api/action/',
    modifyactions: '/api/modifyactions',
}

export function listaction() {
    return request({
        url: actionApi.list,
        method: 'get'
    });
}

export function addorupdateaction (parameter) {
    return request({
        url: actionApi.addorupdateaction,
        method: 'post',
        data: parameter
    })
}

export function getaction(actionid) {
    return request({
        url: actionApi.getaction + actionid,
        method: 'get'
    });
}

export function deleteaction(id) {
    return request({
        url: actionApi.deleteaction + id,
        method: 'delete'
    }); 
}
export function modifyactions (parameter) {
    return request({
        url: actionApi.modifyactions,
        method: 'post',
        data: parameter
    })
}