<template>

	<!-- Projects Table Column -->
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
		<template #title>
			<a-row type="flex" align="middle">
				<a-col :span="24" :md="12">
					<h5 class="font-semibold m-0">Action Lists</h5>
				</a-col>
				<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
				</a-col>
			</a-row>
		</template>
		<a-table :columns="columns" :data-source="data" :pagination="true">		
			<template slot="editBtn" slot-scope="row">
				<a-button shape="circle" @click="editaction(row)" icon="edit">
				</a-button>
			</template>

			<template slot="delBtn" slot-scope="row">
				<a-button shape="circle" @click="delaction(row)" icon="delete">
				</a-button>
			</template>

		

		</a-table>
	</a-card>
	<!-- / Projects Table Column -->

</template>

<script>
    import {deleteaction} from '@/api/action';

	export default ({
		props: {
			data: {
				type: Array,
				default: () => [],
			},
			columns: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {
				// Active button for the "Projects" table's card header radio button group.
				projectHeaderBtns: 'all',
			}
		},
		methods: {
			delaction(id){
				deleteaction(id).then(data=>{
					for (var i=0; i < this.data.length; i++)
						if (this.data[i].actionid==id)
							this.data.splice(i,1);
				})
			},
		editaction(id){
			this.$router.push({path:'/goalactionedit',query:{actionid:id}});
		}
		}
	})

</script>