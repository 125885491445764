<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div>
		<!-- Products Table -->
		<a-row :gutter="24" type="flex">

			<!-- Products Table Column -->
			<a-col :span="24" class="mb-24">

				<!-- Products Table Card -->
				<CardActionTable
					:data="table1Data"
					:columns="table1Columns"
				></CardActionTable>
				<!-- / Products Table Card -->

				<a-button class="fab" shape="circle" @click="addAction">
					<font-awesome-icon icon="plus"/>
				</a-button>

			</a-col>
			<!-- / Products Table Column -->

		</a-row>
		<!-- / Products Table -->

	</div>
</template>

<script>

	// "Products" table component.
	import CardActionTable from '../components/Cards/CardActionTable' ;
	import request from '../utils/request';
	import {listaction} from '@/api/action';
	
	// "Products" table list of columns and their properties.
	const table1Columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			
		},
		{
			title: 'Message',
			dataIndex: 'actionmessage',
		
		},
		{
			title: 'Type',
			dataIndex: 'actiontype',
			
		},
		{
			title: 'Actiontaker',
			dataIndex: 'actionto',
			
		},
		{
			title: 'URL',
			dataIndex: 'actionurl',
			
		},
		{
			title: 'Goal',
			dataIndex: 'goalname',
			
		},
		{
			title: '',
			dataIndex:'actionid',
			scopedSlots: { customRender: 'editBtn' },
			width: 50,
		},
		{
			title: '',
			dataIndex:'actionid',
			key:'id2',
			scopedSlots: { customRender: 'delBtn' },
			width: 50,
		},
		
	];

	// "Authors" table list of rows and their properties.
	const table1Data = [];
	
	export default ({
		components: {
			CardActionTable,
		},
		methods: {
			addAction(){
			this.$router.push({path:'/goalactionedit',query:{actionid:0}});
		},
		},
		data() {
			return {
				// Associating "Products" table data with its corresponding property.
				table1Data: table1Data,

				// Associating "Products" table columns with its corresponding property.
				table1Columns: table1Columns,
			}
		},
		mounted() {
			
			listaction().then(data=>{
				this.table1Data = data;
			});
		},
	})

</script>

<style lang="scss">
</style>